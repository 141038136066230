export default function ApplicationLogo(props) {
    return (
        <svg {...props} viewBox="0 0 309 62.6">
            <g>
                <g id="Layer_1">
                    <g>
                        <polygon points="52.5 62.6 52.5 18 29.5 41.1 0 11.6 0 1.1 29.5 30.5 60 0 60 62.6 52.5 62.6" />
                        <polygon
                            fill="#0d9488"
                            points="29.5 61.2 0 31.7 0 21.2 29.5 50.7 29.5 61.2"
                        />
                    </g>
                    <path d="M94.5,46.6h-3.9V15.7h0l15.7,22.3-1.7-.3,15.6-21.9h0v30.9h-4v-21.8l.2,2-11.3,16h0l-11.5-16,.7-1.9v21.6h.2ZM141.1,46.6v-10l-11.1-19.9h4.9l8.9,16.5h-1.2l8.8-16.5h4.9l-11.2,19.9v10h-4,0ZM165.9,16.6h8c2.2,0,4,.4,5.6,1.1,1.5.7,2.7,1.8,3.6,3.2.9,1.4,1.3,3,1.3,5h0c0,1.2-.2,2.4-.5,3.5-.4,1.1-.9,2.2-1.7,3.1-.8.9-1.8,1.7-3.1,2.2-1.3.6-2.8.8-4.6.8h-4.4v11h-4v-29.9h-.2ZM169.9,31.7h4.4c1.2,0,2.2-.2,2.9-.6.8-.4,1.4-.9,1.8-1.5.4-.6.8-1.2,1-1.9.2-.7.3-1.3.3-1.8h0c0-.6,0-1.2-.3-1.9-.2-.7-.5-1.2-1-1.8-.5-.5-1-1-1.8-1.3-.7-.3-1.6-.5-2.7-.5h-4.6v11.2h0ZM194.7,46.6v-29.9h4v26.1h15.2v3.8h-19.2ZM225.5,46.6h-3.9l13.1-31.2h.2l13,31.2h-4.6l-9.8-24.9,2.9-1.9-10.9,26.7h0ZM228.1,38.7l1.2-3.5h11.1l1.3,3.5h-13.6ZM276.4,40.8l1.7,3.6c-.5.3-1.2.7-2,1.1-.9.4-1.9.8-3,1-1.1.3-2.4.4-3.7.4h0c-2.3,0-4.4-.4-6.3-1.2s-3.4-1.9-4.7-3.3c-1.3-1.4-2.3-3-3-4.8-.7-1.8-1.1-3.7-1.1-5.8h0c0-2.2.4-4.3,1.1-6.2.7-1.9,1.8-3.5,3.1-4.9,1.3-1.4,2.9-2.5,4.7-3.2,1.8-.8,3.8-1.2,5.9-1.2h0c1.8,0,3.4.2,4.9.7s2.7,1.1,3.8,1.7h0l-1.6,3.7c-.8-.6-1.8-1.1-3-1.6s-2.5-.7-3.9-.7h0c-1.5,0-2.8.3-4.1.9-1.3.6-2.5,1.4-3.4,2.5-1,1-1.8,2.3-2.3,3.7-.6,1.4-.8,2.9-.8,4.5h0c0,1.6.3,3.1.8,4.5s1.2,2.6,2.2,3.6,2.1,1.8,3.5,2.4c1.4.6,2.9.9,4.6.9h0c1.5,0,2.9-.2,4.1-.7s2.2-1,2.9-1.6h-.4ZM289,46.6v-29.9h19.5v3.8h-15.4v9.1h13.8v3.8h-13.8v9.3h16v3.8h-20.1Z" />
                </g>
            </g>
        </svg>
    );
}
